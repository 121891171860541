@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "~slick-carousel/slick/slick.css";
@import "../../node_modules/slick-carousel/slick/slick.css";
@import "../../node_modules/slick-carousel/slick/slick-theme.css";
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");
@import "~@fortawesome/fontawesome-svg-core/styles.css";

#website {
  background-color: #fff;
  max-width: 1680px;
}

@font-face {
  font-family: "Pretendard-Thin";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff")
    format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Light";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff")
    format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-ExtraBold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff")
    format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Black";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff")
    format("woff");
  font-weight: 900;
  font-style: normal;
}

html {
  line-height: 1.15;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  // -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
}

body {
  overflow-x: hidden !important;
}
header {
  font-family: "Noto Sans KR", sans-serif;
  color: #1a283e !important;
}

body {
  margin: 0;
  max-width: 1680px !important;
  font-family: "Pretendard", sans-serif;
  min-width: 320px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: var(--color-primary);
}

:root {
  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-weight-bolder: 900;

  --line-height-base: 28px;
  --line-height-small: 24px;
  --line-height-medium: 28px;
  --line-height-large: 32px;
  --line-height-h6-base: 24px;
  --line-height-h6-medium: 28px;
  --line-height-h5-base: 28px;
  --line-height-h5-medium: 30px;
  --line-height-h4-medium: 34px;
  --line-height-h3-base: 36px;
  --line-height-h3-medium: 40px;
  --line-height-h3-large: 52px;
  --line-height-h2-base: 44px;
  --line-height-h2-medium: 50px;
  --line-height-h2-large: 55px;
  --line-height-h1-base: 40px;
  --line-height-h1-medium: 52px;
  --line-height-h1-large: 65px;
  --line-height-h1-x-large: 75px;

  --container-width-base: 276px;
  --container-width-extra-small: 324px;
  --container-width-small: 424px;
  --container-width-medium: 680px;
  --container-width-large: 868px;
  --container-width-extra-large: 1016px;
  --container-width-x-large: 1280px;

  --container-max-width: 40em;

  --space-base: 10px;
  --space-line: 20px;
  --space-adjusted-line: 15px;
  --space-small: 30px;
  --space-middle: 45px;
  --space-medium: 60px;
  --space-large: 80px;
  --space-xl: 100px;
  --space-xxl: 150px;

  --grid-span: 25px;
  --grid-max-width: calc(1280px - 50px);
  --grid-padding-top: 36px;
  --grid-padding-bottom: 40px;
  --grid-padding-left: var(--grid-padding-bottom);
  --grid-padding-right: var(--grid-padding-left);
  --grid-border-radius: 24px;
  --grid-intro-header-padding-top: 100px;
  --grid-intro-header-padding-bottom: 50px;
  --grid-body-padding-top: var(--space-small);
  --grid-body-padding-bottom: var(--space-small);

  --space-navbar-height: 80px;

  --font-size-base: 16px;
  --font-size-tiny: 12px;
  --font-size-small: 14px;
  --font-size-medium: 18px;
  --font-size-large: 20px;
  --font-size-h6-base: 16px;
  --font-size-h6-medium: 16px;
  --font-size-h5-base: 18px;
  --font-size-h5-medium: 20px;
  --font-size-h5-large: 24px;
  --font-size-h4-base: 20px;
  --font-size-h4-medium: 24px;
  --font-size-h4-large: 28px;
  --font-size-h3-base: 28px;
  --font-size-h3-medium: 32px;
  --font-size-h3-large: 44px;
  --font-size-h2-base: 32px;
  --font-size-h2-medium: 40px;
  --font-size-h2-large: 50px;
  --font-size-h1-base: 40px;
  --font-size-h1-medium: 50px;
  --font-size-h1-large: 60px;
  --font-size-h1-x-large: 75px;

  --color-cole-gray: #efeff4;
  --color-blue: #337ef3;
  --color-primary: #1a283e;
  --color-yellow: #f6d662;
  --color-gray: #d1d4d8;
  --color-green: #009688;
  --color-paleGray: #838b96;
  --color-lightBlue: #89b4f8;
  --color-ultraLightBlue: #b1cefa;
  --color-ultraLightGreen: #cceae7;
  --color-ultraLightYellow: #fdf6de;
  --color-superUltraLightBlue: #dbe9ff;
  --color-cole-primary: #1b3154;
  --color-cole-lightPrimary: #7b879c;
}

// grid

.grid-intro-header {
  max-width: calc(var(--grid-max-width) + var(--grid-span) * 2);
  box-sizing: border-box;
  margin: 0 auto;
  padding: var(--grid-intro-header-padding-top)
    calc(var(--grid-padding-left) + var(--grid-span)) var(--grid-padding-bottom)
    calc(var(--grid-padding-right) + var(--grid-span));
}

.grid {
  max-width: var(--grid-max-width);
  margin-left: auto;
  margin-right: auto;
  grid-column-gap: var(--grid-span);
  grid-row-gap: var(--grid-span);
  padding-left: var(--grid-span);
  padding-right: var(--grid-span);
  padding-top: 0;
  padding-bottom: 0;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.feature-grid {
  padding-top: var(--space-xl);
  max-width: var(--grid-max-width);
  margin: 0 auto;
  &--last {
    padding-bottom: var(--space-large);
  }
}

.feature-grid-wrapper {
  width: 920px;
  margin: 0 auto;
}

.grid-item {
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.large-span-6 {
  grid-column: span 6;
}

.large-span-12 {
  grid-column: span 12;
}

.large-span-8 {
  grid-column: span 8;
}
.large-span-4 {
  grid-column: span 4;
}

.grid-item-header {
  padding: var(--space-small) var(--space-middle);
}

.feature-item-header {
  padding: var(--space-medium) var(--space-middle) 0 var(--space-middle);
}

.stock-container-body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  padding: var(--grid-body-padding-top) var(--grid-padding-left)
    var(--grid-body-padding-bottom) var(--grid-padding-right);
  &--full-screen {
    padding: 0;
  }
}

.grid-intro-header .h2 {
  font-size: var(--font-size-h2-large);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-h2-large);
}

.grid-item-header .h3 {
  font-size: var(--font-size-h3-medium);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-h3-medium);
}
.feature-item-header .h4 {
  font-size: var(--font-size-h4-medium);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-h4-medium);
}

.feature-container {
  border-radius: var(--grid-border-radius);
  min-height: 300px;
}

@media (max-width: 1292px) {
  :root {
    --grid-span: 25px;
  }
}

@media (max-width: 1024px) {
  :root {
    --grid-span: 20px;
    --grid-intro-header-padding-top: var(--space-xl);
    --grid-padding-top: 30px;
    --grid-padding-bottom: 35px;
  }
  .feature-grid-wrapper {
    width: 719px;
    width: auto;
    margin: 0 auto;
  }
  .medium-span-12 {
    grid-column: span 12;
  }
  .medium-span-6 {
    grid-column: span 6;
  }
  .medium-span-8 {
    grid-column: span 8;
  }
  .medium-span-4 {
    grid-column: span 4;
  }
  .grid-item-header {
    padding: var(--space-small) var(--space-small);
  }
  .feature-item-header {
    padding: var(--space-medium) var(--space-small) 0 var(--space-small);
  }
}
@media (max-width: 718px) {
  :root {
    --grid-span: 20px;
    --grid-max-width: 480px;
    --grid-intro-header-padding-top: var(--space-large);
    --grid-border-radius: 15px;
  }
  .feature-grid {
    &--last {
      padding-bottom: var(--space-medium);
    }
  }
  .feature-grid-wrapper {
    max-width: var(--grid-max-width);
    width: auto;
    margin: 0 auto;
  }
  .small-span-12 {
    grid-column: span 12;
  }
  .small-span-6 {
    grid-column: span 6 !important;
  }
  .small-span-6 .stock-container-body {
    padding: var(--space-base);
    &--pricing {
      padding: var(--grid-body-padding-top) var(--grid-padding-left)
        var(--grid-body-padding-bottom) var(--grid-padding-right);
    }
  }
  .grid-item-header {
    padding: var(--space-line) var(--space-small);
  }
  .feature-item-header {
    padding: var(--space-small) var(--space-small) 0 var(--space-small);
  }
  .grid-intro-header .h2 {
    font-size: var(--font-size-h2-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h2-medium);
  }
  .grid-item-header .h3 {
    font-size: var(--font-size-h3-base);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-base);
  }
  .feature-item-header .h4 {
    font-size: var(--font-size-h4-base);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h4-base);
  }
  .stock-container-body {
    padding: var(--space-base);
    &--full-screen {
      padding: 0;
    }
    &--pricing {
      padding: var(--grid-body-padding-top) var(--grid-padding-left)
        var(--grid-body-padding-bottom) var(--grid-padding-right);
    }
  }
}
@media (max-width: 480px) {
  :root {
    --grid-span: 15px;
  }
}

// TEXT STYLES
.h1 {
  font-size: var(--font-size-h1-base);
  line-height: var(--line-height-h1-base);
}
.h2 {
  font-size: var(--font-size-h2-base);
  line-height: var(--line-height-h2-base);
}
.h3 {
  font-size: var(--font-size-h3-base);
  line-height: var(--line-height-h3-base);
  margin-bottom: var(--space-base);
}
.h4 {
  font-size: var(--font-size-h4-base);
  line-height: var(--line-height-h4-base);
}
.h5 {
  font-size: var(--font-size-h5-base);
  line-height: var(--line-height-h5-base);
}
.h6 h6 {
  font-size: var(--font-size-h6-base);
  line-height: var(--line-height-h6-base);
}

.caption {
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-base);
}

.light {
  font-weight: var(--font-weight-light) !important;
}
.normal {
  font-weight: var(--font-weight-normal);
}
.bold {
  font-weight: var(--font-weight-bold) !important;
}
.bolder {
  font-weight: var(--font-weight-bolder);
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}

.link-text {
  color: var(--color-blue);
  font-weight: var(--font-weight-bolder);
  margin-top: var(--space-base);
  cursor: pointer;
}
.link-text:after {
  content: "›";
  font-family: "Font Awesome 5 Free";
  // font-size: var(--font-size-h3-medium);
  display: inline-block;
  margin-left: 0.5em;
}

.link-text-yellow {
  color: var(--color-yellow);
  font-weight: var(--font-weight-bolder);
  margin-top: var(--space-base);
  cursor: pointer;
}
.link-text-yellow:after {
  content: "›";
  font-family: "Font Awesome 5 Free";
  // font-size: var(--font-size-h3-medium);
  display: inline-block;
  margin-left: 0.5em;
}

.link-text-no-arrow {
  color: var(--color-blue);
  font-weight: var(--font-weight-bolder);
  margin-top: var(--space-base);
}

.cole-gray-bg {
  background-color: var(--color-cole-gray);
}
.primary-bg {
  background-color: var(--color-primary);
}

.header-effect .h1,
.header-effect .h2,
.header-effect .h3,
.header-effect .h4 {
  margin-bottom: 15px !important;
  font-weight: var(--font-weight-bold) !important;
}

.header-effect .h5 {
  margin-bottom: var(--space-base);
  font-weight: var(--font-weight-bold) !important;
}

.footer-menu .h6 {
  color: var(--color-paleGray);
  font-weight: var(--font-weight-bold);
}
.footer-menu a {
  margin-bottom: calc(var(--space-line) / 2);
  color: #fff !important;
  width: fit-content;
}

.footer-menu-isNew:after {
  content: " ✨";
}

.footer-policy a {
  margin-bottom: calc(var(--space-base) / 2);
  color: var(--color-paleGray) !important;
  width: fit-content;
}

.footer-company p {
  width: fit-content;
  margin-bottom: calc(var(--space-base) / 2);
  font-weight: var(--font-weight-light);
}

.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-start {
  align-items: flex-start;
}

.v-align-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-align-center {
  display: flex;
  justify-content: center;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.p-relative {
  position: relative;
}

ul {
  list-style: none;
  padding-inline-start: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
}

mark {
  background-color: var(--color-yellow);
  color: black;
}

u {
  text-decoration-color: var(--color-yellow);
}

.tab-wrapper {
  position: relative;
  text-align: center;
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
}

.gr-tabs {
  background: var(--color-cole-gray);
  border-radius: 25px;
  transition: transform 0.3s ease-in-out;
  padding: 0 5px;
}

.gr-tabs li {
  margin: 10px 15px !important;
  display: block;
}

.gr-tabs a {
  padding: 15px 20px;
  color: var(--color-paleGray);
  display: block;
  font-size: var(--font-size-h5-medium);
  margin: 0 !important;
  white-space: nowrap;
  cursor: pointer;
}

.gr-tabs a[aria-selected="true"] {
  background: var(--color-primary);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 8%);
  border-radius: 20px;
  color: #fff;
}

.gr-tabs a[aria-selected="false"]:hover,
.gr-tabs a[aria-selected="false"]:focus {
  background: var(--color-cole-lightPrimary);
  border-radius: 20px;
  color: #fff;
}

.gr-pricing-block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 16rem;
  background-color: #fff;
  &-white {
    background-color: var(--color-cole-gray);
  }
}

.primary-pricing h1 {
  font-size: var(--font-size-h1-medium);
  color: var(--color-blue);
}

.gr-pricing-block .primary-pricing {
  padding-top: var(--space-line);
  padding-bottom: var(--space-base);
}

.primary-pricing {
  margin: 24px 0;
}

.display-none {
  display: none;
}
.d-flex {
  display: flex;
}
.d-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w-h-100 {
  width: 100%;
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.max-width-100 {
  max-width: 100%;
}
.h-100 {
  height: 100%;
}
.h-auto {
  height: auto !important;
  min-height: auto !important;
}

.p-base {
  padding: var(--space-base);
}
.p-small {
  padding: var(--space-small);
}
.p-xs {
  padding: var(--space-base);
}

.pb-medium {
  padding-bottom: var(--space-medium) !important;
}
.pb-small {
  padding-bottom: var(--space-small) !important;
}
.pb-base {
  padding-bottom: var(--space-base) !important;
}

.pt-pb-medium {
  padding-top: var(--space-medium) !important;
  padding-bottom: var(--space-medium) !important;
}

.pt-pb-small {
  padding-bottom: var(--space-small) !important;
  padding-top: var(--space-small) !important;
}
.pt-pb-base {
  padding-bottom: var(--space-base) !important;
  padding-top: var(--space-base) !important;
}

.pt-pb-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.pt-medium {
  padding-top: var(--space-medium) !important;
}
.pt-small {
  padding-top: var(--space-small) !important;
}
.pt-line {
  padding-top: var(--space-line);
}
.pt-base {
  padding-top: var(--space-base) !important;
}
.pt-0 {
  padding-top: 0 !important;
}

.pl-pr-line {
  padding-left: var(--space-line);
  padding-right: var(--space-line);
}
.pl-pr-small {
  padding-left: var(--space-small);
  padding-right: var(--space-small);
}
.pl-pr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ml-mr-0 {
  margin-left: 0;
  margin-right: 0;
}
.mt-auto {
  margin-top: auto;
}
.ml-auto {
  margin-left: auto;
}
.ml-base {
  margin-left: var(--space-base);
}
.ml-small {
  margin-left: var(--space-small);
}
.ml-line {
  margin-left: var(--space-line);
}
.mr-base {
  margin-right: var(--space-base);
}
.mr-line {
  margin-right: var(--space-line);
}
.mr-small {
  margin-right: var(--space-small);
}
.mt-medium {
  margin-top: var(--space-medium);
}
.mt-small {
  margin-top: var(--space-small);
}
.mt-line {
  margin-top: var(--space-line);
}
.mt-base {
  margin-top: var(--space-base);
}
.mt-mb-base {
  margin-top: var(--space-base);
  margin-bottom: var(--space-base);
}
.mt-mb-small {
  margin-top: var(--space-small);
  margin-bottom: var(--space-small);
}
.mt-mb-medium {
  margin-top: var(--space-medium);
  margin-bottom: var(--space-medium);
}
.mb-medium {
  margin-bottom: var(--space-medium);
}
.mb-small {
  margin-bottom: var(--space-small);
}
.mb-line {
  margin-bottom: var(--space-line);
}
.mb-base {
  margin-bottom: var(--space-base);
}
.mb-medium {
  margin-bottom: var(--space-medium);
}
.mb-0 {
  margin-bottom: 0 !important;
}

// NAV

#channel-chat-button {
  position: fixed;
  z-index: 999;
  right: 10px; /* 화면 오른쪽으로부터의 거리, 숫자만 변경 */
  bottom: 2em; /* 화면 아래쪽으로부터의 거리, 숫자만 변경 */
  cursor: pointer;
}

.kakao-chat-icon {
  width: 144px;
  height: 64px;
}

.navbar {
  height: var(--space-navbar-height);
  max-width: 1680px;
  width: 100%;
  background-color: #fff;
  padding: 0 2rem;
  font-size: 1.2rem;
}

.navbar-scroll {
  position: fixed;
  z-index: 10;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray);
}

.margin-navbar {
  margin-top: var(--space-navbar-height);
  // margin-top: 32px;
}

.nav-logo {
  text-decoration: none;
  width: 50px;
  height: 50px;
}

.nav-center-block {
  margin-left: auto;
  margin-right: auto;
  // display: flex;
  // align-items: center;
}

.nav-links {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 20px;
  list-style: none;
  align-items: center;
  margin-bottom: 0px;
  padding-left: 0px;
}

.nav-link {
  text-decoration: none;
  color: #1a283e;
  transition: 0.3s all;
  background-color: #fff;
  &-blue {
    color: #006aff;
    &:hover {
      color: #006aff !important;
      background-color: #efeff4;
    }
  }
  &:hover {
    color: #1a283e;
    background-color: #efeff4;
    border-radius: 5px;
  }
}

.nav-subMenu {
  display: none;
}

.nav-item {
  display: inline-flex;
  position: relative;
  &:hover {
    .nav-subMenu {
      display: block;
    }
  }
  &-right-item {
    display: block;
  }
  &-sidebar-show {
    display: none;
  }
}

.nav-item-sidebar-only {
  &-sidebar-show {
    display: none;
  }
}

.nav-icon {
  display: none;
}

.kakao-icon {
  width: 35px;
  height: 35px;
}

.nav-subMenu {
  z-index: 5;
  text-transform: none;
  padding-left: 0;
  text-decoration: none;
  list-style: none;
  min-width: 14rem;
  text-align: center;
  position: absolute;
  background-color: #fff;
  color: #1a283e;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
  transition: visibility 0ms 100ms, pointer-events 0ms,
    opacity 100ms cubic-bezier(0.4, 0, 1, 1),
    transform 100ms cubic-bezier(0.4, 0, 1, 1),
    width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  left: -10px;
  top: 100%;
  &-isOpened {
    opacity: 1;
    pointer-events: auto;
  }
}

.nav-subMenu-item {
  list-style: none;
  text-decoration: none;
  cursor: pointer;
  padding: 0.714rem 1rem;
  font-weight: var(--font-weight-normal);
  font-size: 1rem;
  text-align: left;
  &:hover {
    background-color: #efeff4;
  }
}

.nav-subMenu-item-isNew {
  font-weight: var(--font-weight-bolder);
}

.nav-subMenu-item-isNew:after {
  content: " ✨";
}

.nav-subMenu-item[aria-hidden="true"] {
  background-color: var(--color-cole-gray);
  color: var(--color-paleGray);
  cursor: initial;
}

.text-nav-link {
  font-size: 16px;
  padding-right: 15px !important;
  padding-left: 15px !important;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: var(--color-primary);
}

.text-nav-link-b {
  padding: 9px 20px !important;
  border-radius: 100px !important;
  border: 1px solid #1a283e;
}
.text-nav-link-active {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: var(--color-gray);
}

@media only screen and (max-width: 760px) {
  .navbar {
    // position: relative;
    z-index: 10;
  }
  .navbar-scroll {
    max-width: 1680px;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: auto;
    text-align: left;
    grid-gap: 0;
    width: 100%;
    max-width: 414px;
    top: 80px;
    right: -100%;
    z-index: 3;
    transition: 0.3s all;
  }

  .nav-links.active {
    right: 0;
    background-color: #fff;
    // border-left: 1px solid #d1d4d8;
    overflow: auto;
    position: fixed;
    // display: block;
    transform: translate3d(0, 0, 0);
    // right: 0;
    bottom: 0;
    padding-bottom: 100px;
  }

  .nav-link {
    &:hover {
      background-color: #fff;
    }
  }

  .nav-icon {
    display: flex;
    cursor: pointer;
    padding: 0.714rem;
    border-radius: 5px;
    font-size: 1.5rem;
    &:hover {
      background-color: #efeff4;
    }
  }

  .nav-item {
    width: 100%;
    padding: 0.714rem 1rem;
    display: inline-block;
    background-color: #fff;
    border-bottom: 1px solid #d1d4d8;
    &-right-item {
      display: none;
    }
    &:first-child {
      border-top: 1px solid #d1d4d8;
    }
    &-sidebar-show {
      display: block;
    }
    &:hover {
      .nav-subMenu {
        display: none;
      }
    }
  }

  .nav-item-sidebar-only {
    &-sidebar-show {
      display: block;
    }
  }

  .nav-subMenu-item {
    &:hover {
      background-color: #f9f9f9;
    }
  }

  .nav-subMenu-isClicked {
    display: block;
    background-color: #f9f9f9;
  }

  .nav-subMenu {
    &-isOpened {
      opacity: 0;
      pointer-events: none;
    }
  }
  .nav-subMenu.active {
    opacity: 1;
    pointer-events: auto;
    position: relative;
    top: 0;
    text-align: left;
  }

  .overlay-bg {
    box-sizing: border-box;
    z-index: 1;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.38);
    transition: 0.3s all;
  }

  .accordion-arrow {
    position: relative;
    display: block;
    width: 1em;
    height: 1em;
    // border-radius: 100%;
    // border: 1px solid #fff;
    margin: 0 0 0 auto;
    padding: 0;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.6, 1);
    color: transparent;
    background-color: #fff;
    overflow: hidden;
    white-space: nowrap;
  }

  .accordion-arrow::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 7px;
    height: 7px;
    border-right: 2px solid #1a283e;
    border-bottom: 2px solid #1a283e;
    transform: translate3d(-50%, calc(-50% - 2px), 0) rotate(45deg);
  }

  [transition-state="on"] .accordion-show {
    display: none;
  }

  [transition-state="off"] .accordion-arrow {
    transform: rotate(-180deg);
  }
}

// Sub nav

.subnav-container {
  padding: var(--space-large) 0 var(--space-medium) 0;
}

table th,
table td {
  padding: 0.5em;
  border: 1px solid #999;
}
table th {
  text-align: left;
  background-color: #ccc;
}

table {
  margin-top: 0.3em;
}

th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}

th {
  padding: 0.3em;
}

// Button

button {
  appearance: auto;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: transparent;
  box-sizing: none;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 6px;
  border-width: 0;
  border-style: 0;
  border-color: transparent;
  border-image: none;
}

.footer-icon-button {
  color: #fff;
  font-size: var(--space-small);
}
.footer-icon-button:not(:first-child) {
  margin-left: var(--space-line);
}

.primary-button {
  background-color: var(--color-cole-primary);
  color: #fff;
  padding: 13px 18px;
  font-size: var(--font-size-h5-medium);
  font-weight: var(--font-weight-bold);
  border-radius: 5px;
}
.primary-button:hover {
  background-color: var(--color-cole-lightPrimary);
}
.primary-button:disabled {
  background-color: var(--color-paleGray);
  cursor: initial;
}

.blue-button {
  background-color: var(--color-blue);
  color: #fff;
  padding: 13px 18px;
  font-size: var(--font-size-h5-medium);
  font-weight: var(--font-weight-bold);
  border-radius: 5px;
}
.blue-button:hover {
  background-color: var(--color-lightBlue);
}

.main-section {
  overflow: hidden;
  position: relative;
  text-align: center;
}

.content {
  margin-right: auto;
  margin-left: auto;
  width: var(--container-width-base);
}

.main-section .content {
  padding: var(--space-medium) 0;
}

.white-color {
  color: #fff;
}

.blue-color {
  color: var(--color-blue);
}
.primary-color {
  color: var(--color-primary);
}
.paleGray-color {
  color: var(--color-paleGray);
}

.bg-invert {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.bg-cole-gray {
  background-color: var(--color-cole-gray);
}

.bg-ultra-blue {
  background-color: var(--color-superUltraLightBlue);
}

.bg-ultra-green {
  background-color: var(--color-ultraLightGreen);
}
.bg-ultra-yellow {
  background-color: var(--color-ultraLightYellow);
}

.body-label {
  color: var(--color-blue);
  font-weight: var(--font-weight-bolder);
}

.bg-custom {
  position: relative;
}

.bg-custom .image {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-custom .content {
  z-index: 2;
  position: relative;
}

.image-appro {
  position: relative;
  overflow: hidden;
  display: block;
  max-width: 100%;
}

.bg-custom .image-appro {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-invert .text-on-bg-background::after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  mix-blend-mode: multiply;
  background: linear-gradient(
    180deg,
    var(--color-paleGray) 20%,
    rgba(255, 255, 255, 0.2) 70%
  );
}

// #ab7343

.body-section {
  min-height: 632px;
}

.gr-grid {
  display: flex;
  flex-wrap: wrap;
}

.gr-grid-wrapper {
  width: 100%;
}

.gr-grid-column {
  display: flex;
  flex-direction: column;
}

.gr-2-column-medium {
  flex-wrap: wrap;
}

.gr-2-column-wrapper {
  flex-wrap: wrap;
}

.gr-2-column {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  &-no-margin {
    margin-bottom: 0;
  }
}

.gr-2-60-column,
.gr-2-40-column {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.gr-column {
  margin-bottom: var(--space-line);
  padding-left: var(--space-line) / 2;
  padding-right: var(--space-line) / 2;
}

.multi-column-grid {
  align-items: stretch;
  align-content: stretch;
}

.wide-content-wrapper {
  margin: 0 30px;
}

.gr-4-column-wrapper {
  flex-wrap: wrap;
}

.gr-3-column-wrapper {
  flex-wrap: wrap;
}

.gr-3-column {
  margin-bottom: var(--space-line);
  &-pl-pr {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.gr-4-column {
  margin-bottom: var(--space-line);
}

.gr-4-column {
  width: 100%;
  margin-bottom: var(--space-line);
  padding-left: 10px;
  padding-right: 10px;
}

.feature-block {
  background-color: var(--color-cole-gray);
  border-radius: 5px;
  padding: var(--space-small);
  min-height: 320px;
  display: flex;
}

// Image set

@media (min-width: 150px) {
  .pos-main-image-bg {
    background: url("../img/pos/pos-main-image-bg-xs.png") center;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .marketing-main-image-bg {
    background: url("../img/marketing/marketing-main-image-bg-xs.png") center;
    height: 100%;
    background-size: cover;
  }
  .franchise-main-image-bg {
    background: url("../img/franchise/franchise-main-image-bg-xs.png") center;
    height: 100%;
    background-size: cover;
  }
}

@media (min-width: 480px) {
  .pos-main-image-bg {
    background: url("../img/pos/pos-main-image-bg-sm.png") center;
    height: 100%;
    background-repeat: no-repeat;
  }
  .marketing-main-image-bg {
    background: url("../img/marketing/marketing-main-image-bg-sm.png") center;
    height: 100%;
    background-size: cover;
  }
  .franchise-main-image-bg {
    background: url("../img/franchise/franchise-main-image-bg-sm.png") center;
    height: 100%;
  }
}

@media (min-width: 718px) {
  .pos-main-image-bg {
    background: url("../img/pos/pos-main-image-bg-md.png") center;
    height: 100%;
    background-repeat: no-repeat;
  }
  .marketing-main-image-bg {
    background: url("../img/marketing/marketing-main-image-bg-md.png") center;
    height: 100%;
  }
  .marketing-body-1-1-bg {
    background: url("../img/marketing/marketing-body-1-1-bg-md.png") no-repeat
      center;
    height: 100%;
  }
  .marketing-body-1-2-bg {
    background: url("../img/marketing/marketing-body-1-2-bg-md.png") no-repeat
      center;
    height: 100%;
  }
  .marketing-body-1-3-bg {
    background: url("../img/marketing/marketing-body-1-3-bg-md.png") no-repeat
      center;
    height: 100%;
  }
  .franchise-main-image-bg {
    background: url("../img/franchise/franchise-main-image-bg-md.png") center;
    height: 100%;
  }
}

@media (min-width: 1024px) {
  .pos-main-image-bg {
    background: url("../img/pos/pos-main-image-bg-lg.png") center;
    height: 100%;
    background-repeat: no-repeat;
  }
  .marketing-main-image-bg {
    background: url("../img/marketing/marketing-main-image-bg-lg.png") center;
    height: 100%;
  }
  .marketing-body-1-1-bg {
    background: url("../img/marketing/marketing-body-1-1-bg-lg.png") no-repeat
      center;
    height: 100%;
  }
  .marketing-body-1-2-bg {
    background: url("../img/marketing/marketing-body-1-2-bg-lg.png") no-repeat
      center;
    height: 100%;
  }
  .marketing-body-1-3-bg {
    background: url("../img/marketing/marketing-body-1-3-bg-lg.png") no-repeat
      center;
    height: 100%;
  }
  .franchise-main-image-bg {
    background: url("../img/franchise/franchise-main-image-bg-lg.png") center;
    height: 100%;
  }
}

@media (min-width: 1292px) {
  .pos-main-image-bg {
    background: url("../img/pos/pos-main-image-bg-xl.png") center;
    height: 100%;
    background-repeat: no-repeat;
  }
  .marketing-main-image-bg {
    background: url("../img/marketing/marketing-main-image-bg-xl.png") center;
    height: 100%;
  }
  .marketing-body-1-1-bg {
    background: url("../img/marketing/marketing-body-1-1-bg-xl.png") no-repeat
      center;
    height: 100%;
  }
  .marketing-body-1-2-bg {
    background: url("../img/marketing/marketing-body-1-2-bg-xl.png") no-repeat
      center;
    height: 100%;
  }
  .marketing-body-1-3-bg {
    background: url("../img/marketing/marketing-body-1-3-bg-xl.png") no-repeat
      center;
    height: 100%;
  }
  .franchise-main-image-bg {
    background: url("../img/franchise/franchise-main-image-bg-xl.png") center;
    height: 100%;
  }
}

@media (min-width: 150px) {
  .content {
    width: var(--container-width-extra-small);
  }
  .main-section .wide-content-wrapper {
    margin-bottom: var(--space-small);
  }
  .main-section .content {
    padding: var(--space-medium) 0;
  }
  .body-section .content {
    padding: var(--space-small) 0;
  }
  .stock-main {
    min-height: 453px;
  }
  .content #pos-pos-body {
    min-height: 230px;
  }
  .content #pos-dashboard-body {
    min-height: 230px;
  }
  .body-bg-image .image {
    position: relative;
    width: 100%;
    padding-left: var(--space-small);
    padding-right: var(--space-small);
  }
  #landing-main-image .content {
    min-height: 350px;
  }
  #landing-main-image h1 {
    font-size: var(--font-size-h1-medium);
    font-weight: var(--font-weight-bolder);
  }
  .main-section h1 {
    font-size: var(--font-size-h1-medium);
    font-weight: var(--font-weight-bolder);
    line-height: var(--line-height-h1-medium);
  }
  .main-section h2 {
    font-size: var(--font-size-h2-medium);
    font-weight: var(--font-weight-bolder);
  }
  #section-header h2 {
    font-size: var(--font-size-h2-medium);
    font-weight: var(--font-weight-bolder);
    line-height: var(--line-height-h2-base);
  }
  #section-header h3 {
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bolder);
  }
  #section-header h4 {
    font-size: var(--font-size-h4-base);
    font-weight: var(--font-weight-bolder);
  }
  #section-sub h3 {
    font-size: var(--font-size-h3-base);
    font-weight: var(--font-weight-bold);
  }
  #section-sub h4 {
    font-size: var(--font-size-h4-base);
    font-weight: var(--font-weight-bold);
  }
  #section-header h5 {
    font-size: var(--font-size-h5-base);
    font-weight: var(--font-weight-bold);
  }
  .gr-3-column {
    width: 100%;
    margin-bottom: var(--space-small);
  }
  .gr-4-column {
    width: 100%;
    margin-bottom: var(--space-small);
  }
  .gr-3-column-wrapper {
    flex-wrap: nowrap;
  }
  .primary-pricing {
    &-border {
      border-right: none;
    }
  }
  .gr-4-column .p-small {
    padding-left: var(--space-base);
    padding-right: var(--space-base);
  }
  #footer .gr-4-column {
    width: 100%;
    align-items: center;
  }
  .text-break-line {
    display: none;
  }
  .line-breaker {
    display: block;
  }
  .gr-tabs {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 480px) {
  .content {
    width: var(--container-width-small);
  }
  .main-section .wide-content-wrapper {
    margin-bottom: var(--space-small);
  }
  .main-section .content {
    padding: var(--space-medium) 0;
  }
  .body-section .content {
    padding: var(--space-medium) 0;
  }
  .stock-main {
    min-height: 630px;
  }
  .content #pos-pos-body {
    min-height: 305px;
  }
  .content #pos-dashboard-body {
    min-height: 305px;
  }
  .body-bg-image .image {
    position: relative;
    width: 100%;
    padding-left: var(--space-small);
    padding-right: var(--space-small);
  }
  #landing-main-image .content {
    min-height: 550px;
  }
  #landing-main-image h1 {
    font-size: var(--font-size-h1-medium);
    font-weight: var(--font-weight-bolder);
  }
  .main-section h2 {
    font-size: var(--font-size-h2-medium);
    font-weight: var(--font-weight-bolder);
  }
  #section-header h2 {
    font-size: var(--font-size-h2-medium);
    font-weight: var(--font-weight-bolder);
    line-height: var(--line-height-h2-medium);
  }
  #section-header h3 {
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bolder);
  }
  #section-header h4 {
    font-size: var(--font-size-h4-medium);
    font-weight: var(--font-weight-bolder);
  }
  #section-sub h3 {
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bold);
  }
  #section-sub h4 {
    font-size: var(--font-size-h4-medium);
    font-weight: var(--font-weight-bold);
  }
  #section-header h5 {
    font-size: var(--font-size-h5-base);
    font-weight: var(--font-weight-bold);
  }
  .gr-3-column-wrapper {
    flex-wrap: nowrap;
  }
  .gr-3-column {
    width: 100%;
    margin-bottom: var(--space-small);
  }
  .gr-4-column {
    width: 100%;
    margin-bottom: var(--space-small);
  }
  .primary-pricing {
    &-border {
      border-right: none;
    }
  }
  #footer .gr-4-column {
    width: 50%;
    align-items: flex-start;
  }
  .gr-4-column .p-small {
    padding-left: var(--space-base);
    padding-right: var(--space-base);
  }
  .feature-block {
    min-height: 350px;
  }
  .text-break-line {
    display: none;
  }
  .line-breaker {
    display: block;
  }
  .gr-tabs {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 718px) {
  .content {
    width: var(--container-width-medium);
  }
  .main-section .wide-content-wrapper {
    margin-bottom: var(--space-medium);
  }
  .main-section .content {
    padding: var(--space-large) 0;
  }
  .body-section .content {
    padding: var(--space-medium) 0;
  }
  .stock-main {
    min-height: 602px;
  }
  .content #pos-pos-body {
    min-height: 250px;
  }
  .content #pos-dashboard-body {
    min-height: 540px;
  }
  .body-bg-image .image {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  #landing-main-image .content {
    min-height: 630px;
  }
  #landing-main-image h1 {
    font-size: 50px;
    font-weight: var(--font-weight-bolder);
    letter-spacing: 0px;
    line-height: var(--line-height-h1-medium);
  }
  .main-section h1 {
    font-size: 50px;
    font-weight: var(--font-weight-bolder);
    letter-spacing: 0px;
    line-height: var(--line-height-h1-medium);
  }
  .main-section h2 {
    font-size: 40px;
    font-weight: var(--font-weight-bolder);
    letter-spacing: -2px;
    line-height: var(--line-height-h2-medium);
  }
  #section-header h2 {
    font-size: var(--font-size-h2-large);
    font-weight: var(--font-weight-bolder);
    line-height: var(--line-height-h2-large);
  }
  #section-header h3 {
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bolder);
  }
  #section-header h4 {
    font-size: var(--font-size-h4-medium);
    font-weight: var(--font-weight-bolder);
  }
  #section-sub h3 {
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bold);
  }
  #section-sub h4 {
    font-size: var(--font-size-h4-medium);
    font-weight: var(--font-weight-bold);
  }
  #section-header h5 {
    font-size: var(--font-size-h5-medium);
    font-weight: var(--font-weight-bold);
  }
  .gr-2-column-wrapper {
    flex-wrap: nowrap;
  }
  .gr-2-column {
    width: 50%;
    &-right {
      padding-right: calc(var(--space-medium) - var(--space-line));
    }
    &-left {
      padding-left: calc(var(--space-medium) - var(--space-line));
    }
  }
  .gr-2-60-column {
    width: 60%;
    &-right {
      padding-right: calc(var(--space-medium) - var(--space-line));
    }
    &-left {
      padding-left: calc(var(--space-medium) - var(--space-line));
    }
  }
  .gr-2-40-column {
    width: 40%;
    &-right {
      padding-right: calc(var(--space-medium) - var(--space-line));
    }
    &-left {
      padding-left: calc(var(--space-medium) - var(--space-line));
    }
  }
  .gr-3-column-wrapper {
    flex-wrap: wrap;
    display: flex;
  }
  .gr-3-column {
    width: 33.33%;
  }
  .primary-pricing {
    &-border {
      border-right: 1px solid var(--color-gray);
    }
    &-even-border {
      border-right: none;
    }
  }
  .gr-4-column-wrapper {
    flex-wrap: wrap;
  }
  .gr-4-column {
    width: 50%;
  }
  #footer .gr-4-column {
    width: 50%;
    align-items: flex-start;
  }
  .feature-block {
    min-height: 380px;
  }
  .text-break-line {
    display: block;
  }
  .line-breaker {
    display: block;
  }
  .gr-tabs {
    display: inline-flex;
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .content {
    width: var(--container-width-large);
  }
  #landing-main-image h1 {
    font-size: 60px;
    font-weight: var(--font-weight-bolder);
    letter-spacing: 0px;
    line-height: var(--line-height-h1-large);
  }
  .main-section h1 {
    font-size: 60px;
    font-weight: var(--font-weight-bolder);
    letter-spacing: 0px;
    line-height: var(--line-height-h1-large);
  }
  .main-section h2 {
    font-size: 50px;
    font-weight: var(--font-weight-bolder);
    letter-spacing: 0px;
    line-height: var(--line-height-h2-large);
  }
  .main-section .wide-content-wrapper {
    margin-bottom: var(--space-medium);
  }
  .main-section .content {
    padding: var(--space-xl) 0;
  }
  .body-section .content {
    padding: var(--space-large) 0;
  }
  .stock-main {
    min-height: 672px;
  }
  .content #pos-pos-body {
    min-height: 321px;
  }
  .content #pos-dashboard-body {
    min-height: 477px;
  }
  .body-bg-image .image {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  #landing-main-image .content {
    min-height: 787px;
  }
  .gr-2-column-wrapper {
    flex-wrap: nowrap;
  }
  .gr-2-column {
    width: 50%;
    &-right {
      padding-right: calc(var(--space-large) - var(--space-line));
    }
    &-left {
      padding-left: calc(var(--space-large) - var(--space-line));
    }
  }
  .gr-2-60-column {
    width: 60%;
    &-right {
      padding-right: calc(var(--space-large) - var(--space-line));
    }
    &-left {
      padding-left: calc(var(--space-large) - var(--space-line));
    }
  }
  .gr-2-40-column {
    width: 40%;
    &-right {
      padding-right: calc(var(--space-large) - var(--space-line));
    }
    &-left {
      padding-left: calc(var(--space-large) - var(--space-line));
    }
  }
  .gr-3-column-wrapper {
    flex-wrap: wrap;
    display: flex;
  }
  .gr-3-column {
    width: 33.33%;
  }
  .primary-pricing {
    &-border {
      border-right: 1px solid var(--color-gray);
    }
    &-even-border {
      border-right: 1px solid var(--color-gray);
    }
  }
  .gr-4-column-wrapper {
    flex-wrap: wrap;
  }
  .gr-4-column {
    width: 25%;
  }
  #footer .gr-4-column {
    width: 25%;
    align-items: flex-start;
  }
  .gr-4-column .p-small {
    padding-left: 0;
    padding-right: 0;
  }
  .feature-block {
    min-height: 350px;
  }
  .line-breaker {
    display: none;
  }
}
@media (min-width: 1292px) {
  .content {
    width: var(--container-width-extra-large);
  }
  .main-section .wide-content-wrapper {
    margin-bottom: var(--space-medium);
  }
  .main-section .content {
    padding: var(--space-xl) 0;
  }
  .content #pos-pos-body {
    min-height: 382px;
  }
  .content #pos-dashboard-body {
    min-height: 456px;
  }
  .body-section .content {
    padding: var(--space-large) 0;
  }
  .stock-main {
    min-height: 877px;
  }
  .body-bg-image .image {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  #landing-main-image .content {
    min-height: 644px;
  }
  .gr-2-column-wrapper {
    flex-wrap: nowrap;
  }
  .gr-2-column {
    width: 50%;
    &-right {
      padding-right: var(--space-large);
    }
    &-left {
      padding-left: var(--space-large);
    }
  }
  .gr-2-60-column {
    width: 60%;
    &-right {
      padding-right: var(--space-large);
    }
    &-left {
      padding-left: var(--space-large);
    }
  }
  .gr-2-40-column {
    width: 40%;
    &-right {
      padding-right: var(--space-large);
    }
    &-left {
      padding-left: var(--space-large);
    }
  }
  .gr-3-column-wrapper {
    flex-wrap: wrap;
    display: flex;
  }
  .gr-3-column {
    width: 33.33%;
  }
  .gr-4-column {
    width: 25%;
  }
  .primary-pricing {
    &-border {
      border-right: 1px solid var(--color-gray);
    }
    &-even-border {
      border-right: 1px solid var(--color-gray);
    }
  }
  .gr-4-column-wrapper {
    flex-wrap: wrap;
  }
  .gr-4-column {
    width: 25%;
  }
  .gr-4-column .p-small {
    padding-left: 0;
    padding-right: 0;
  }
}

.body-section {
  min-height: 632px;
}
#landing-uses-block {
  height: 325px;
}
.gr-2-column .support-block {
  height: 65px;
}
.support-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.support-logo {
  width: 65px;
  height: 65px;
}

.pc-download-logo {
  width: 213px;
  height: 64px;
  cursor: pointer;
}

.app-store-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--space-small);
}

.app-store-wrapper-landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--space-small);
}

.new-appstore-logo {
  width: 180px;
  height: 60px;
  cursor: pointer;
}

.new-appstore-logo-landing {
  width: 180px;
  height: 60px;
  cursor: pointer;
}

.new-appstore-logo:hover {
  opacity: 0.58;
}
.new-appstore-logo-landing:hover {
  opacity: 0.58;
}

.appstore-logo {
  width: 141.6px;
  height: 54px;
}
.mini-appstore-logo {
  width: 128px;
  height: 40px;
  cursor: pointer;
}

.feature-comp-wrapper {
  position: relative;
}

.feature-comp {
  border-radius: 5px;
  border: 1px solid var(--color-gray);
  z-index: 2;
  width: 100%;
  background-color: #fff;
  text-align: center;
  height: 100%;
  position: relative;
  cursor: pointer;
  &-header {
    padding: var(--space-small) var(--space-small) 0 var(--space-small);
  }
}
.feature-comp:hover,
.feature-comp:focus {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
  transition: box-shadow 400ms ease-out;
}

.feature-comp-grid {
  align-content: start;
  display: flex;
  flex-direction: column;
}

.feature-comp-grid .feature-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.feature-body .text-body {
  padding: 0 var(--space-small);
}

.feature-body .image-body {
  margin-bottom: 0;
}

.review-image {
  width: 100%;
  padding: var(--space-small);
}

// html .feature-modal[aria-modal="true"] {
//   overflow: auto;
// }

// html .ReactModal__Body--open,
// body .ReactModal__Body--open {
//   overflow-y: hidden !important;
//   overflow-x: auto !important;
// }

.feature-modal {
  position: fixed;
  width: 80%;
  z-index: 1040;
  top: 50%;
  left: 50%;
  border-radius: 9px;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: var(--space-small) var(--space-line);
  outline: none;
  overflow: auto;
  max-height: 65%;
}
.feature-backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
  transition: all ease 1s;
}

.video-modal {
  width: 100%;
  height: 90%;
  z-index: 1040;
  border-radius: 9px;
  background-color: transparent;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: var(--space-small) var(--space-line);
  outline: none;
  overflow: auto;
}

// .feature-list ul {
//   list-style: none;
//   margin-left: 0;
//   padding-left: 1em;
//   text-indent: -1em;
// }

// .feature-list li {
//   margin-bottom: var(--space-line);
// }

// .feature-list ul li::before {
//   content: "\2714";
//   color: var(--color-blue);
//   margin-right: var(--space-base);
// }

ul.check-list > li::before {
  content: " ";
  width: 1em;
  height: 0.5em;
  top: 0.25em;
  transform: rotate(-45deg);
  border-style: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0.2em;
  border-bottom-width: 0.2em;
  color: var(--color-blue);
}
ul.check-list > li {
  padding-left: calc(calc(var(--space-base) / 2) + 1.75em);
  font-weight: var(--font-weight-bold);
  // font-size: var(--font-size-h6-medium);
}

ul > .check-list {
  list-style: none;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

ul > li,
ol > li {
  position: relative;
}

ul > li::before,
ol > li::before {
  overflow: hidden;
  display: inline-block;
  position: absolute;
  margin-left: -1.75em;
  text-align: left;
}

.check-list li {
  margin-bottom: var(--space-base);
}

.pos-feature-block {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.pos-feature-block[aria-selected="true"] {
  background-color: var(--color-cole-gray);
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.25);
}

.pos-feature-block p {
  margin-bottom: 0;
}

.dashboard-feature-block {
  padding: 10px 15px;
  border-radius: 5px;
  background-color: var(--color-cole-gray);
  cursor: pointer;
}

.dashboard-feature-block[aria-selected="true"] {
  background-color: #fff;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.25);
}

.dashboard-feature-block p {
  margin-bottom: 0;
}

.footer-icon {
  width: 33px;
  height: 30px;
}

// Legacy css code
.sub-title-section {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 1.875em;
  padding-bottom: 2em;
}

.white-bordered-button {
  background-color: #fff;
  min-width: 8em;
  color: #1a283e;
  font-weight: 700;
  font-size: var(--font-size-h5-medium);
  font-weight: var(--font-weight-bold);
  text-align: center;
  border: solid 1px #1a283e;
  cursor: pointer;
  padding: 13px 18px;
  border-radius: 5px;
}

.white-bordered-button:active {
  background-color: var(--color-primary);
  color: #fff;
}

.close-box:after {
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  content: "\f00d";
  font-size: var(--font-size-h5-medium);
  position: absolute;
  color: #fff;
  background-color: #1a283e;
  text-align: center;
  padding: 6px;
  right: 13px;
  // width: 5em;
  // height: 5em;
}
.close-box:hover {
  opacity: 0.38;
}

.feature-content-body-section {
  padding-top: 1em;
  padding-bottom: 1em;
}

.feature-content-body-section .description {
  padding-top: 0.25em;
  // padding-bottom: 1em;
}

[transition-state="on"] .feature-body-img-wrapper {
  display: none;
}
.feature-body-img-wrapper {
  flex-wrap: wrap;
  display: flex;
}

.subcontent-link-wrapper {
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
  width: fit-content;
  cursor: pointer;
  display: table;
}

.subcontent-accordion-arrow::after {
  font-family: "Font Awesome 5 Free";
  content: "\f061";
  font-weight: bold;
  font-size: -0.8em;
  position: absolute;
  right: -2.5em;
  top: -3px;
  color: #fff;
  background-color: var(--color-blue);
  border-radius: 50%;
  text-align: center;
  padding: 0.25em;
  width: 2em;
  height: 2em;
}
.subcontent-accordion-arrow:hover {
  opacity: 0.38;
}

.subcontent-accordion-vertical-arrow::after {
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  content: "\f063";
  position: absolute;
  right: -2.5em;
  top: -3px;
  color: #fff;
  background-color: var(--color-blue);
  border-radius: 50%;
  text-align: center;
  padding: 0.25em;
  width: 2em;
  height: 2em;
}
.subcontent-accordion-vertical-arrow:hover {
  opacity: 0.38;
}

.feature-divider-bottom {
  margin: 0;
  border: 0.5px solid #f1f1f1;
}

.feature-section {
  position: relative;
  overflow: visible;
}

.overflow-visible {
  overflow: visible !important;
}

.feature-section .feature-aside {
  position: absolute;
  z-index: 1;
  height: 100%;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
}

.feature-aside {
  display: none;
}

.feature-category-wrapper {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  left: 0;
  bottom: 0;
  right: 0;
  top: 90px;
  z-index: 99;
  padding: 0 0 0 10px;
  border-left: 1px solid #1a283e;
  margin-left: 15px;
  margin-right: 15px;
  background: transparent !important;
  margin-bottom: 80px;
}

.feature-category-wrapper .content {
  width: auto;
  padding-top: 0;
}

.feature-item {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.feature-item a {
  color: #1a283e;
  font-weight: var(--font-weight-bold);
}

.item-active a {
  font-weight: bold;
}
.item-active {
  font-weight: bold;
}

.feature-content .content {
  padding-top: 0;
  background-color: transparent;
}

.feature-wide-header-section {
  padding-bottom: 1.5em;
}
.feature-wide-header-section .header-content-image {
  padding-bottom: 0;
}
.header-content-image {
  padding-bottom: 1.5em;
}

.feature-content-header-section {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.feature-auto-collapse {
  display: none;
}

.feature-auto-collapse-reverse {
  display: block;
}

.feature-divider-bottom {
  margin: 0;
  border: 0.5px solid #f1f1f1;
}
.feature-divider:first-child {
  margin: 0;
  border: 0.5px solid var(--color-paleGray);
}

.feature-title {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
.feature-title:first-child {
  padding-top: 0em;
}

.feature-content-body-section {
  padding-top: 1em;
  padding-bottom: 1em;
}

.feature-content-body-section .description {
  padding-top: 0.25em;
  // padding-bottom: 1em;
}

.feature-content-body-image {
  width: 50%;
  padding: 1em;
}

[transition-state="on"] .feature-body-img-wrapper {
  display: none;
}
.feature-body-img-wrapper {
  flex-wrap: wrap;
  display: flex;
}

.feature-header-img-wrapper {
  flex-wrap: wrap;
  display: flex;
}
[transition-state="on"] .feature-header-img-wrapper {
  display: none;
}

.feature-content-header-button {
  cursor: pointer;
  background-color: #fff;
}

.feature-content-header-button:hover {
  background-color: rgba(#1a283e, 0.1);
}

.legacy-accordion-arrow {
  position: relative;
  display: block;
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
  border: 1px solid #1a283e;
  margin: 0;
  padding: 0;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.6, 1);
  color: transparent;
  background-color: #1a283e;
  overflow: hidden;
  white-space: nowrap;
}
.legacy-accordion-arrow::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: translate3d(-50%, calc(-50% - 2px), 0) rotate(45deg);
  // transform: translatetween(-50%, calc(-50% - 2px), 0) rotate(45deg);
  // -moz-transform: translatetween(-50%, calc(-50% - 2px), 0) rotate(45deg);
  // -webkit-transform: translatetween(-50%, calc(-50% - 2px), 0) rotate(45deg);
  // -ms-transform: translatetween(-50%, calc(-50% - 2px), 0) rotate(45deg);
}
[transition-state="off"] .legacy-accordion-arrow {
  transform: rotate(-180deg);
}
[transition-state="on"] .legacy-accordion-show {
  display: none;
}

.events-container {
  max-width: 1024px;
  margin: 0 auto;
}

.lg-events-button {
  background-color: #0084e1;
  color: #fff;
  padding: 13px 18px;
  font-size: var(--font-size-h3-medium);
  font-weight: var(--font-weight-bold);
  border-radius: 5px;
  height: 100px;
  width: 80%;
}

.lg-events-button:hover {
  background-color: #b0d8f5;
}

.event-bar {
  height: var(--space-navbar-height);
  max-width: 1680px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  background-color: #ecf5ff;
  // background: rgb(255, 255, 255);
  // background: linear-gradient(
  //   90deg,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(230, 0, 126, 1) 20%,
  //   rgba(230, 0, 126, 1) 50%,
  //   rgba(230, 0, 126, 1) 80%,
  //   rgba(255, 255, 255, 1) 100%
  // );
  padding: 0 2rem;
  font-size: 1.2rem;
  position: absolute;
  top: var(--space-navbar-height);
  left: 0;
  right: 0;
  z-index: 9;
}
.event-bar .contents {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.event-button {
  border-radius: 25px;
  border: 2px solid #0084e1;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-h6-medium);
  font-weight: var(--font-weight-bold);
  color: #0084e1;
  padding: 0 var(--space-line);
  cursor: pointer;
}
.event-button:hover {
  background-color: #fff;
  color: #0084e1;
}

.lg-events-blue-color {
  color: #0084e1;
}

.featured-block {
  padding-top: var(--space-large);
  padding-bottom: var(--space-xxl);
}

.featured-block > .feature-container {
  border-radius: 10px;
  border: 1px solid var(--color-primary);
  min-height: 250px;
  display: flex;
}

.featured-block > .feature-container > .left {
  width: 35%;
}

.featured-block > .feature-container > .right {
  width: 65%;
  padding: var(--space-medium);
}

.featured-left-image {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media (max-width: 373.98px) {
  .body-section {
    min-height: 373px;
  }
  .pos-body-3-height {
    min-height: 355px;
  }
  .body-two-column {
    display: flex;
    flex-direction: column;
  }
  .body-left-column {
    flex-direction: column-reverse;
    height: 100%;
  }
  #landing-review-body {
    height: 791px;
  }
  #landing-uses-block {
    height: 350px;
  }
  #footer .flex-space-between {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .collapse-align {
    &-left {
      justify-content: center;
    }
    &-right {
      align-items: center;
    }
    &-horizontaol-left {
      align-items: center;
    }
  }
  .feature-modal {
    width: 90%;
  }
  .feature-content-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-effect .h3 {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 374px) {
  .body-section {
    min-height: 473px;
  }
  .body-two-column {
    display: flex;
    flex-direction: column;
  }
  .body-left-column {
    flex-direction: column-reverse;
    height: 100%;
  }
  #landing-body {
    height: 473px;
  }
  #landing-review-body {
    height: 791px;
  }
  .gr-grid-wrapper {
    margin-bottom: var(--space-small);
    padding-left: var(--space-small) / 2;
    padding-right: var(--space-small) / 2;
  }
  #landing-uses-block {
    height: 350px;
  }
  #footer .flex-space-between {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .collapse-align {
    &-left {
      justify-content: center;
    }
    &-right {
      align-items: center;
    }
    &-horizontaol-left {
      align-items: center;
    }
  }
  .feature-content-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-effect .h3 {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 480px) {
  .body-section {
    min-height: 642px;
  }
  .pos-body-3-height {
    min-height: 555px;
  }
  .body-two-column {
    display: flex;
    flex-direction: column;
  }
  .body-left-column {
    flex-direction: column-reverse;
    height: 100%;
  }
  #landing-review-body {
    height: 998px;
  }
  .gr-grid-wrapper {
    margin-bottom: var(--space-medium);
    padding-left: var(--space-medium) / 2;
    padding-right: var(--space-medium) / 2;
  }
  #landing-uses-block {
    height: 440px;
  }
  .gr-2-column .support-block {
    height: 85px;
  }
  .support-logo {
    width: 65px;
    height: 65px;
  }
  #footer .flex-space-between {
    display: flex;
    flex-direction: row;
    text-align: none;
  }
  .collapse-align {
    &-left {
      justify-content: flex-start;
    }
    &-right {
      align-items: flex-end;
    }
    &-horizontaol-left {
      align-items: flex-start;
    }
  }
  .feature-modal {
    width: 90%;
  }
  .feature-content-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-effect .h3 {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 717px) {
  #main-content .body-two-column {
    flex-wrap: wrap;
    min-height: auto !important;
    margin-top: var(--space-small);
  }
  .image-appro #image {
    width: auto !important;
    height: auto !important;
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #landing-body {
    height: auto !important;
  }
}

@media (min-width: 718px) {
  .replace-image {
    display: none;
  }
}

@media (min-width: 718px) {
  .body-section {
    min-height: 532px;
  }
  .pos-body-3-height {
    min-height: 635px;
  }
  .body-left-column {
    flex-direction: row;
  }
  #main-content .body-two-column {
    margin-top: var(--space-medium);
  }
  #landing-review-body {
    height: 550px;
  }
  .gr-grid-wrapper {
    margin-bottom: var(--space-large);
    padding-left: var(--space-large) / 2;
    padding-right: var(--space-large) / 2;
  }
  #landing-uses-block {
    height: 360px;
  }
  .gr-2-column .support-block {
    height: 100px;
  }
  .support-logo {
    width: 75px;
    height: 75px;
  }
  .app-store-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .app-store-wrapper-landing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .new-appstore-logo {
    width: 250px;
    height: 80px;
  }
  .new-appstore-logo-landing {
    width: 180px;
    height: 60px;
  }
  .pc-download-logo {
    width: 212px;
    height: 64px;
    cursor: pointer;
  }
  .mini-appstore-logo {
    width: 171px;
    height: 53px;
  }
  .feature-comp {
    &-header {
      padding: var(--space-medium) var(--space-small) 0 var(--space-small);
    }
  }
  .feature-modal {
    width: 50%;
  }
  .feature-content-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 1024px) {
  #main-content .body-two-column {
    margin-top: var(--space-medium);
  }
  #landing-body {
    height: 533px;
  }
  #landing-review-body {
    height: 725px;
  }
  #landing-uses-block {
    height: 260px;
  }
  .pos-body-3-height {
    min-height: 700px;
  }
  .gr-2-column .support-block {
    height: 125px;
  }
  .support-logo {
    width: 90px;
    height: 90px;
  }
  .appstore-logo {
    width: 188px;
    height: 72px;
  }
  .app-store-wrapper-landing {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .new-appstore-logo-landing {
    width: 150px;
    height: 48px;
  }
  .feature-modal {
    width: 50%;
  }
  .feature-content-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 1292px) {
  .body-section {
    min-height: 694px;
  }
  .pos-body-3-height {
    min-height: 610px;
  }
  #main-content .body-two-column {
    margin-top: var(--space-medium);
  }
  #landing-body {
    height: 694px;
  }
  #landing-review-body {
    height: 945px;
  }
  #landing-uses-block {
    height: 323px;
  }
  .feature-modal {
    width: 50%;
  }
  .feature-section .feature-aside {
    width: calc((100% - 1016px) / 2);
  }
  .feature-aside {
    display: block;
  }
  .legacy-accordion-arrow {
    display: none;
  }
  .feature-auto-collapse {
    display: block;
  }
  .feature-auto-collapse-reverse {
    display: none;
  }
}

// 2022

.label-icon {
  width: 3.5em;
  aspect-ratio: 1.3/1;
}

.brand-logo {
  height: 2.5em;
  // width: 100%;
}

// Slider

.slides-container {
  position: relative;
  white-space: nowrap;
}

.slide-width {
  width: 450px;
}

.slide-width:focus-visible {
  outline: none;
}

.video-thumbnail {
  // width: 1280px;
  // height: 720px;
  width: 100%;
  padding: 0 var(--space-base);
}

.hero {
  overflow: hidden;
  position: relative;
  text-align: center;
}

._content {
  margin: 0 auto;
}

#label {
  color: var(--color-blue);
}

.arrow-left,
.arrow-right {
  height: 45px;
  width: 45px;
  background-color: var(--color-blue);
  border-radius: 50%;
  padding: 0.5em;
}

.arrow-left::after {
  content: "←";
  font-size: var(--font-size-h5-medium);
  font-weight: bold;
  color: #fff;
  text-align: center;
  cursor: pointer;
  display: block;
}

.arrow-right::after {
  content: "→";
  font-size: var(--font-size-h5-medium);
  font-weight: bold;
  color: #fff;
  text-align: center;
  cursor: pointer;
  display: block;
}

.arrow-right:hover {
  background-color: var(--color-lightBlue);
}
.arrow-left:hover {
  background-color: var(--color-lightBlue);
}

.check-icon::after {
  font-family: "Font Awesome 5 Free";
  font-size: var(--font-size-small);
  font-weight: bold;
  content: "\f00c";
  color: #fff;
  background-color: var(--color-lightBlue);
  border-radius: 50%;
  text-align: center;
  padding: 0.35em;
  cursor: pointer;
}

.excel-icon {
  margin-bottom: var(--space-base);
}

.excel-icon::after {
  font-family: "Font Awesome 5 Free";
  font-size: var(--font-size-h4-large);
  font-weight: bold;
  content: "\f1c3";
  color: var(--color-green);
  text-align: center;
  // padding: 0.25em;
}

.dashboard-icon {
  margin-bottom: var(--space-base);
}

.dashboard-icon::after {
  font-family: "Font Awesome 5 Free";
  font-size: var(--font-size-h4-large);
  font-weight: bold;
  content: "\f0ce";
  color: var(--color-blue);
  text-align: center;
  // padding: 0.25em;
}

.history-icon {
  margin-bottom: var(--space-base);
}

.history-icon::after {
  font-family: "Font Awesome 5 Free";
  font-size: var(--font-size-h4-large);
  font-weight: bold;
  content: "\f1da";
  color: var(--color-yellow);
  text-align: center;
  // padding: 0.25em;
}

.case-block {
  border: 1px solid var(--color-gray);
  border-radius: 15px;
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
  // height: 100%;
}

.case-wrapper {
  padding: var(--space-line);
}

.case-block .upper {
  padding: var(--space-small);
}

.tag-comp {
  background-color: var(--color-blue);
  border-radius: 20px;
  color: #fff;
}

.case-block .bottom {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.marketing-wrapper {
  padding: var(--space-line);
}

.marketing-block {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.marketing-block .upper {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.marketing-block .bottom {
  padding: var(--space-small);
  height: 100%;
}

.two-wide-wrapper {
  padding: var(--space-line);
}
.one-wide-wrapper {
  padding: var(--space-line);
}

.two-wide-block {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.two-wide-block .upper {
  width: 100%;
  border-radius: 12px;
  min-height: 350px;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.group-action-image {
  background-size: cover;
  background-image: url("../img/retail/retail-body-6-2.png");
  pointer-events: none;
  border-radius: 12px;
  background-repeat: no-repeat;
  height: 100%;
  flex: 1;
}
.action-image {
  background-size: cover;
  background-image: url("../img/retail/retail-body-6-3.png");
  pointer-events: none;
  border-radius: 12px;
  background-repeat: no-repeat;
  height: 100%;
  flex: 1;
}
.two-wide-block .bottom {
  padding: var(--space-small);
  color: #fff;
  height: 35%;
}

.kiosk-features-wrapper {
  margin-bottom: var(--space-medium);
}

.kiosk-feature {
  overflow: hidden;
}

.kiosk-feature .upper {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 15px;
}

.kiosk-feature .bottom {
  padding-top: var(--space-line);
}

.slide-width .hardware-slide-comp {
  padding: 0 var(--space-base);
}

.hardware-block {
  background-color: var(--color-cole-gray);
  border-radius: 15px;
  overflow: hidden;
  padding: var(--space-line) var(--space-small);
  width: 100%;
}

.refer-block {
  overflow: hidden;
  // padding: var(--space-line) var(--space-small);
  width: 100%;
  border-radius: 5px;
}

.upper-wrapper .hardware-block {
  width: 100%;
}

.hardware-container .bottom-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-line);
}

.hardware-container .event-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-line);
}

.line-through {
  text-decoration-line: line-through;
}

.bottom-wrapper .hardware-wrapper {
  width: calc(33% - var(--space-line));
}

.event-wrapper .hardware-wrapper {
  width: calc(33% - var(--space-line));
}

.event-hardware-block {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(#0084e1, 1) 100%
  );
  border-radius: 15px;
  overflow: hidden;
  padding: var(--space-line) var(--space-small);
  width: 100%;
  cursor: pointer;
}

.hardware-wrapper a,
.hardware-slide-comp a {
  display: block;
}

.features-section {
  width: 100%;
  margin-top: var(--space-line);
}

.features-section:not(:first-child) {
  margin-top: var(--space-medium);
}

.features-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: var(--space-line);
}

.features-block {
  width: calc(25% - var(--space-line));
  padding: var(--space-line);
}

.feature-inner {
  background-color: #fff;
  border-radius: 12px;
  padding: var(--space-line);
  width: 100%;
  height: 100%;
}

.feature-inner .upper img {
  width: 3.5em;
  aspect-ratio: 1/1;
}

.feature-inner .upper .h5 {
  margin-top: var(--space-base);
}

.feature-inner .upper {
}

.feature-inner .bottom {
  margin-top: var(--space-base);
}

.retail-shareable-inner {
  background-color: #fff;
  border-radius: 12px;
  padding: var(--space-line);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.retail-shareable-inner > .inner-image {
  margin-left: var(--space-line);
  margin-top: var(--space-line);
  padding: var(--space-base) 0 0 var(--space-base);
  // border-top: 2px solid var(--color-primary);
  // border-left: 2px solid var(--color-primary);
  background-color: var(--color-cole-gray);
  border-radius: 9px;
  box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  min-height: 200px;
  min-width: 200px;
  font-size: var(--font-size-h6-medium);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
}

.inner-image .pdf-shape {
  padding: 3px 10px;
  width: fit-content;
  background-color: #be1b00;
  font-size: var(--font-size-h6-base);
  color: #fff;
  font-weight: var(--font-weight-bold);
  border-radius: 5px;
  margin-bottom: var(--space-base);
}

.pricing-block {
  background-color: var(--color-cole-gray);
  border-radius: 12px;
  padding: var(--space-line);
  text-align: center;
  &--white {
    background-color: #fff;
  }
}

.store-picture-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.store-picture-wrapper {
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  isolation: isolate;
  border-radius: 26px;
  aspect-ratio: 1/1;
}

.store-picture-wrapper--1::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background-color: var(--color-cole-gray);
  background-image: url("../img/cases/case-retail-ref-1.png");
  background-repeat: none;
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}
.store-picture-wrapper--2::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background-color: var(--color-cole-gray);
  background-image: url("../img/cases/case-retail-ref-2.png");
  background-repeat: none;
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}
.store-picture-wrapper--3::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background-color: var(--color-cole-gray);
  background-image: url("../img/cases/case-retail-ref-3.png");
  background-repeat: none;
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}
.store-picture-wrapper--4::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background-color: var(--color-cole-gray);
  background-image: url("../img/cases/case-retail-ref-4.png");
  background-repeat: none;
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}

.store-picture-wrapper-service--1::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background-color: var(--color-cole-gray);
  background-image: url("../img/cases/case-service-ref-1.png");
  background-repeat: none;
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}
.store-picture-wrapper-service--2::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background-color: var(--color-cole-gray);
  background-image: url("../img/cases/case-service-ref-2.png");
  background-repeat: none;
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}
.store-picture-wrapper-service--3::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background-color: var(--color-cole-gray);
  background-image: url("../img/cases/case-service-ref-3.png");
  background-repeat: none;
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}
.store-picture-wrapper-service--4::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  content: "";
  background-color: var(--color-cole-gray);
  background-image: url("../img/cases/case-service-ref-4.png");
  background-repeat: none;
  -webkit-background-position: center;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
}

.store-picture-wrapper .store-picture {
  padding: var(--space-small);
}

.primary-pricing .h1 {
  margin-top: var(--space-small);
}

.case-food-extra-feature-container {
  width: 100%;
}

.case-food-extra-feature-wrapper {
  padding: var(--space-line);
}

.case-food-extra-feature-comp {
  padding: var(--space-small);
  background-color: var(--color-cole-gray);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.case-food-extra-feature-comp .upper {
  margin-bottom: var(--space-small);
}

.case-food-extra-feature-comp .bottom {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.case-custom-background {
  background: url("../img/cafe24/cafe24-order-integration.png") center;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  &--order-integration {
    background: url("../img/cafe24/cafe24-order-integration.png") center;
    background-size: cover;
  }
  &--stock-integration {
    background: url("../img/cafe24/cafe24-stock-integration.png") center;
    background-size: cover;
  }
}

.franchise-sub-feature-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.franchise-sub-feature-item-wrapper {
  display: flex;
  align-items: center;
  padding: var(--space-base) var(--space-line);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.25);
  background-color: var(--color-gray);
  border-radius: var(--space-base);
  margin-bottom: var(--space-line);
  min-width: 310px;
}

.franchise-sub-feature-item-wrapper img {
  // background-color: var(--color-primary);
  // padding: var(--space-base);
  // border-radius: 50%;
  width: 5em;
}

.franchise-sub-feature-item-wrapper .h5 {
  margin-left: var(--space-base);
  margin-bottom: 0;
}

.prevent-image-down {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.kds-layout-wrapper {
  border: 1px solid var(--color-blue);
  border-radius: 5px;
  padding: 15px 10px;
  cursor: pointer;
  width: fit-content;
  min-width: 16rem;
  background-color: #fff;
}

.kds-layout-wrapper:hover {
  background-color: var(--color-cole-gray);
}

.kds-layout-wrapper[aria-selected="true"] {
  background-color: var(--color-ultraLightBlue);
}

.kds-layout-wrapper:not(:first-child) {
  border: 1px solid var(--color-blue);
  border-radius: 5px;
  padding: 15px 10px;
  margin-top: var(--space-line);
}

.retail-stats-container {
  width: 100%;
}

.upper > .left,
.upper > .right {
  border-radius: 12px;
  background-color: #fff;
  min-height: 450px;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.upper > .left > .label,
.upper > .right .label {
  padding: var(--space-small);
  font-size: var(--font-size-h4-medium);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue);
}

.upper > .left > .image {
  background-size: cover;
  background-image: url("../img/retail/retail-body-4-1.png");
  pointer-events: none;
  height: 100%;
  width: 80%;
  margin-left: auto;
  flex: 1;
}
.upper > .right > .image {
  background-size: cover;
  background-image: url("../img/retail/retail-body-4-2.png");
  pointer-events: none;
  height: 100%;
  width: 80%;
  margin-left: auto;
  flex: 1;
}

.retail-stats-container > .bottom {
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  min-height: 550px;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.bottom > .label {
  padding: var(--space-small);
  font-size: var(--font-size-h4-medium);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue);
}

.bottom > .image {
  background-size: cover;
  background-image: url("../img/retail/retail-body-4-3.png");
  pointer-events: none;
  height: 100%;
  width: 80%;
  flex: 1;
  margin-left: auto;
}

.stock-container {
  background-color: #fff;
  border-radius: var(--grid-border-radius);
  min-height: 300px;
  display: flex;
  flex-direction: column;
}

.label-wrapper {
  padding: var(--space-base) var(--space-adjusted-line);
  border-radius: 12px;
  text-align: center;
  color: #fff;
  font-size: var(--font-size-h6-base);
  font-weight: var(--font-weight-bold);
  width: fit-content;
  display: flex;
  justify-self: center;
  align-items: center;
  margin-bottom: var(--space-small);
}

.scroll-section {
  display: flex;
  min-height: 37.24vh;
  position: relative;
  width: 100%;
}

.image-container {
  position: relative;
  display: flex;
  width: 100%;
}

.point-scroll-image {
  background: url("../img/cafe24/cafe24-body2-1-2.png") center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 1s;
}

.point-scrolled-image {
  background: url("../img/cafe24/cafe24-body2-1-1.png") center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 1s;
}

.coupon-scroll-image {
  background: url("../img/cafe24/cafe24-body2-3-2.png") center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 1s;
}

.coupon-scrolled-image {
  background: url("../img/cafe24/cafe24-body2-3-1.png") center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 1s;
}

.review-block {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(#f6d662, 1) 100%
  );
  border-radius: 15px;
  overflow: hidden;
  padding: var(--space-line) var(--space-small);
  width: 100%;
  padding-bottom: var(--space-medium);
}

.narrow-feature {
  padding: var(--space-small);
  background-color: var(--color-superUltraLightBlue);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  position: relative;
  min-height: 400px;
  overflow: hidden;
}

.narrow-feature:not(:first-child) {
  margin-top: var(--space-medium);
}

.narrow-feature > .left {
  margin-top: var(--space-base);
  font-size: var(--font-size-h2-medium);
  font-weight: var(--font-weight-bold);
  line-height: var(--font-size-h2-medium);
}

.narrow-feature .right {
  position: absolute;
  top: var(--space-small);
  right: 0;
  width: 70%;
  height: 100%;
  &--waiting {
    background: url("../img/cafe24/cafe24-waiting.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  &--ali {
    background: url("../img/cafe24/cafe24-ali.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  &--tax-refund {
    background: url("../img/cafe24/cafe24-taxrefund.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.ui.selection.dropdown > .fit-width {
  min-width: 12em !important;
}

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
  cursor: pointer;
  position: relative;
  width: auto;
  height: 100% !important;
  line-height: 1.21428571em;
  top: 0;
  right: 0;
  z-index: 1;
  margin: -0.78571429em;
  padding: 0.91666667em;
  opacity: 0.8;
  transition: opacity 0.1s ease;
  align-items: center;
  display: flex;
  justify-content: center;
}

.ui.search.selection.dropdown > input.search {
  line-height: 1.21428571em;
  padding: 0.67857143em 2.1em 0.67857143em 1em;
  min-height: 50px;
}

.ui.selection.active.dropdown {
  border-color: #d1d4d8 !important;
}
.ui.selection.active.dropdown .menu {
  border-color: #d1d4d8 !important;
}
.ui.selection.dropdown:focus {
  border-color: #d1d4d8 !important;
}
.ui.selection.dropdown:focus .menu {
  border-color: #d1d4d8 !important;
}
.ui.selection.active.dropdown:hover {
  border-color: #d1d4d8 !important;
}
.ui.selection.active.dropdown:hover .menu {
  border-color: #d1d4d8 !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  color: #1a283e;
}

.ui.dropdown .menu > .item {
  color: #1a283e;
}

.ui.selection.visible.dropdown > .text:not(.default) {
  color: #1a283e;
}

.responsive-drop-down {
  border: 1px solid var(--color-primary) !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-right: var(--space-adjusted-line) !important;
}

.fit-width {
  width: fit-content;
}

// END

// text adjustment
@media (min-width: 150px) {
  #header .h1 {
    font-size: var(--font-size-h1-base);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h1-base);
    margin-bottom: var(--space-small);
  }
  #sub-header .h2 {
    font-size: var(--font-size-h2-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h2-base);
    margin-bottom: var(--space-line);
  }
  #sub-header .h3 {
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-medium);
  }
  .stock-container-header .h3 {
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-medium);
  }
  .product-feature .h3,
  .pricing-block .h3 {
    font-size: var(--font-size-h3-base);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-base);
  }
  .product-feature p {
    font-size: var(--font-size-base);
  }
  .product-feature .h4 {
    font-size: var(--font-size-h4-base);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h4-base);
  }
  .event-bar .h5 {
    font-size: var(--font-size-h5-base);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h5-base);
  }
  .product-feature .h5,
  .pricing-block .h5,
  .feature-inner .h5 {
    font-size: var(--font-size-h5-base);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h5-base);
  }
  .subnav-wrapper .subnav-comp {
    font-size: var(--font-size-h4-base);
  }
  .detail-block .h3 {
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-base);
  }
}
@media (min-width: 480px) {
  #header .h1 {
    font-size: var(--font-size-h1-base);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h1-base);
    margin-bottom: var(--space-small);
  }
  #sub-header .h4 {
    font-size: var(--font-size-h4-base);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-h4-base);
  }
  .subnav-wrapper .subnav-comp {
    font-size: var(--font-size-h4-medium);
  }
}
@media (min-width: 718px) {
  #header .h1 {
    font-size: var(--font-size-h1-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h1-medium);
    margin-bottom: var(--space-small);
  }
  #header .h4 {
    font-size: var(--font-size-h4-base);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-h4-base);
  }
  #sub-header .h2 {
    font-size: var(--font-size-h2-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h2-medium);
  }
  #sub-header .h4 {
    font-size: var(--font-size-h4-medium);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-h4-base);
  }
  .product-feature .h3,
  .pricing-block .h3 {
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-medium);
  }
  .product-feature .h4 {
    font-size: var(--font-size-h4-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h4-medium);
  }
  .event-bar .h5 {
    font-size: var(--font-size-h5-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h5-medium);
  }
  .product-feature .h5,
  .pricing-block .h5,
  .feature-inner .h5 {
    font-size: var(--font-size-h5-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h5-medium);
  }
  .product-feature p {
    font-size: var(--font-size-medium);
  }
  .detail-block .h3 {
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-medium);
  }
  .label-wrapper {
    font-size: var(--font-size-h4-base);
  }
}
@media (min-width: 1024px) {
  #header .h1 {
    font-size: var(--font-size-h1-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h1-large);
    margin-bottom: var(--space-small);
  }
  #sub-header .h2 {
    font-size: var(--font-size-h2-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h2-large);
  }
  #sub-header .h3 {
    font-size: var(--font-size-h3-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-large);
  }
  .stock-container-header .h3 {
    font-size: var(--font-size-h3-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-large);
  }
  .product-feature .h3,
  .pricing-block .h3 {
    font-size: var(--font-size-h3-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-large);
  }
  .product-feature p {
    font-size: var(--font-size-medium);
  }
  .product-feature .h4 {
    font-size: var(--font-size-h4-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h4-medium);
  }
  .subnav-wrapper .subnav-comp {
    font-size: var(--font-size-h4-large);
  }
  .detail-block .h3 {
    font-size: var(--font-size-h3-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h3-large);
  }
}
@media (min-width: 1292px) {
  #header .h1 {
    font-size: var(--font-size-h1-x-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h1-x-large);
    margin-bottom: var(--space-small);
  }
  #header .h4 {
    font-size: var(--font-size-h4-medium);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-h4-medium);
  }
  #sub-header .h2 {
    font-size: var(--font-size-h2-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h2-large);
  }
  #sub-header .h4 {
    font-size: var(--font-size-h4-large);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-h4-medium);
  }
}

// container adjustment
@media (min-width: 150px) {
  ._content {
    max-width: var(--container-width-extra-small);
  }
  .custom-bg ._content {
    padding-top: var(--space-large);
    padding-bottom: var(--space-base);
  }
  ._content .faq-container {
    max-width: var(--container-width-small);
    margin: auto;
    padding-bottom: var(--space-small);
  }
  ._content #sub-header {
    padding: var(--space-large) 0 var(--space-small) 0;
  }
  .slides-container {
    padding-bottom: var(--space-medium);
  }
  ._content .case-container {
    margin-top: var(--space-large);
    margin-bottom: var(--space-large);
  }
  ._content .marketing-container {
    padding-bottom: var(--space-large);
  }
  ._content .hardware-container {
    padding-bottom: var(--space-large);
  }
  ._content .case-main {
    padding-bottom: var(--space-large);
  }
  #event .hero-header {
    padding: 200px 0 var(--space-large) 0 !important;
  }
  .hero-header {
    padding: var(--space-medium) 0 var(--space-large) 0;
  }
  .hero-header .left {
    padding: var(--space-base);
    width: 100%;
  }
  .left .sub-content {
    justify-content: center;
  }
  .upper > .left {
    width: 100%;
    border-radius: 12px;
    margin-bottom: var(--space-small);
  }
  .upper > .right {
    width: 100%;
    border-radius: 12px;
    margin-bottom: var(--space-small);
  }
  .retail-stats-container > .bottom {
    width: 100%;
    border-radius: 12px;
  }
}
@media (min-width: 480px) {
  ._content {
    max-width: var(--container-width-small);
  }
  ._content .faq-container {
    max-width: var(--container-width-medium);
    margin: auto;
    padding-bottom: var(--space-large);
  }
  ._content .case-container {
    margin-top: var(--space-large);
    margin-bottom: var(--space-large);
  }
  ._content .marketing-container {
    padding-bottom: var(--space-large);
  }
  ._content .hardware-container {
    padding-bottom: var(--space-large);
  }
  ._content .case-main {
    padding-bottom: var(--space-large);
  }
  .hero-header {
    padding: var(--space-medium) 0 var(--space-large) 0;
  }
  .hero-header .left {
    padding: var(--space-small);
    width: 100%;
  }
  .left .sub-content {
    justify-content: center;
  }
}
@media (min-width: 718px) {
  ._content {
    max-width: var(--container-width-medium);
  }
  ._content #sub-header {
    padding: var(--space-xl) 0 var(--space-medium) 0;
  }
  .slides-container {
    padding-bottom: var(--space-large);
  }
  #event .hero-header {
    padding: var(--space-large) 0 var(--space-large) 0 !important;
  }
  .hero-header {
    padding: var(--space-medium) 0 var(--space-large) 0;
  }
  .hero-header .left {
    padding: var(--space-medium);
    width: 100%;
  }
  .left .sub-content {
    justify-content: center;
  }
  .retail-stats-container > .upper {
    display: flex;
    flex-direction: column;
  }
  .upper > .left {
    width: 100%;
    border-radius: 12px;
    margin-bottom: var(--space-medium);
  }
  .upper > .right {
    width: 100%;
    border-radius: 12px;
    margin-bottom: var(--space-medium);
    border-left: none;
  }
  .retail-stats-container > .bottom {
    width: 100%;
    border-radius: 12px;
    border-top: none;
  }
  .retail-shareable-inner {
    height: 200px;
  }
}
@media (min-width: 1024px) {
  ._content {
    max-width: var(--container-width-extra-large);
  }
  ._content .faq-container {
    max-width: var(--container-width-large);
    margin: auto;
    padding-bottom: var(--space-xl);
  }
  ._content #sub-header {
    padding: var(--space-xl) 0 var(--space-large) 0;
  }
  .slides-container {
    padding-bottom: var(--space-xl);
  }
  ._content .case-container {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }
  ._content .marketing-container {
    padding-bottom: var(--space-xxl);
  }
  ._content .hardware-container {
    padding-bottom: var(--space-xl);
  }
  ._content .case-main {
    padding-bottom: var(--space-xxl);
  }
  #event .hero-header {
    padding: var(--space-large) 0 var(--space-xl) 0 !important;
  }
  .hero-header {
    display: flex !important;
    padding: var(--space-medium) 0 var(--space-xl) 0;
    &--reverse {
      flex-direction: row-reverse;
    }
  }
  .hero-header .left {
    padding: var(--space-xl) var(--space-medium) 0 0;
    width: 50%;
    text-align: left;
  }
  .left .sub-content {
    justify-content: flex-start;
  }
  .franchise-sub-feature-item-wrapper {
    min-width: 347px;
    padding: var(--space-base) var(--space-small);
  }
  .retail-stats-container > .upper {
    display: flex;
    flex-direction: row;
  }
  .upper > .left {
    width: 50%;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px;
    border-right: none;
  }
  .upper > .right {
    width: 50%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px;
    border-left: 1px solid var(--color-primary);
  }
  .retail-stats-container > .bottom {
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 1px solid var(--color-primary);
  }
}
@media (min-width: 1292px) {
  ._content {
    max-width: var(--container-width-x-large);
  }
  ._content #sub-header {
    padding: var(--space-xl) 0 var(--space-large) 0;
  }
  .slides-container {
    padding-bottom: var(--space-xl);
  }
  #event .hero-header {
    padding: var(--space-large) 0 var(--space-xxl) 0 !important;
  }
  .hero-header {
    display: flex !important;
    padding: var(--space-medium) 0 var(--space-xxl) 0;
    &--reverse {
      flex-direction: row-reverse;
    }
  }
  .hero-header .left {
    padding: var(--space-xl) var(--space-medium) 0 0;
    width: 50%;
    text-align: left;
  }
  .left .sub-content {
    justify-content: flex-start;
  }
  .retail-stats-container > .upper {
    display: flex;
    flex-direction: row;
  }
  .upper > .left {
    width: 50%;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px;
    border-right: none;
  }
  .upper > .right {
    width: 50%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px;
    border-left: 1px solid var(--color-primary);
  }
  .retail-stats-container > .bottom {
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 1px solid var(--color-primary);
  }
  .retail-shareable-inner {
    height: 230px;
  }
}

// Align adjustment
@media (min-width: 150px) {
  .hero-image-container {
    width: 100%;
    margin-top: var(--space-medium);
  }
  .two-column-wrapper .right-container {
    width: 100%;
    margin-top: 60px;
    margin-bottom: -60px;
  }
  .subnav-container {
    padding: var(--space-medium) 0 var(--space-small) 0;
  }
  .subnav-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: none;
  }
  .subnav-wrapper .subnav-comp {
    width: 100%;
    text-align: center;
    font-size: var(--font-size-h4-large);
    color: var(--color-primary);
    padding: var(--space-base);
    margin-right: 0;
    cursor: pointer;
  }
  .subnav-wrapper .subnav-comp.active {
    color: #fff;
    font-weight: var(--font-weight-bold);
    background-color: var(--color-primary);
    border-radius: 15px;
  }
  .two-column-wrapper {
    &--margin-top {
      margin-top: var(--space-small);
    }
    &--margin-bottom {
      margin-bottom: var(--space-small);
    }
    padding: var(--space-medium) 0;
  }
  .tag-comp {
    padding: 5px var(--space-base);
    font-size: var(--font-size-tiny);
  }
  .features-block {
    width: calc(100% - var(--space-line));
  }
  ._content .one-feature-hero {
    padding-bottom: var(--space-large);
  }
  .case-wrapper {
    padding: 0;
    margin-bottom: var(--space-small);
    width: 100%;
  }
  .pricing-container {
    margin-bottom: var(--space-large);
  }
  .pricing-wrapper {
    width: 100%;
    padding: var(--space-line);
  }
  .pricing-wrapper .pricing-block .h5,
  .pricing-wrapper .pricing-block .h3 {
    margin-bottom: var(--space-base);
  }
  .pricing-block {
    margin-bottom: var(--space-line);
  }
  .retail-excel-container {
    display: flex;
    flex-direction: column;
    margin: var(--space-line) 0 var(--space-small) 0;
    padding-bottom: var(--space-large);
  }
  .retail-excel-container .upper {
    width: 100%;
    margin-bottom: var(--space-small);
  }
  .retail-excel-container .bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .retail-excel-container .bottom .detail-block {
    margin-bottom: var(--space-line);
  }
  .store-picture-container {
    flex-direction: column;
  }
  .store-picture-wrapper {
    margin: 0 0 var(--space-line) 0;
    flex-basis: 100%;
    width: 100%;
  }
  .store-picture-container {
    margin-bottom: var(--space-large);
  }
  .case-food-extra-feature-container {
    display: flex;
    flex-direction: column;
  }
  .case-food-extra-feature-comp {
    margin-bottom: var(--space-small);
    width: 100%;
  }
  .case-custom-background {
    margin-bottom: var(--space-small);
    width: 100%;
  }
  .event-bar .contents {
    flex-direction: column;
    text-align: center;
  }
  .event-bar {
    height: auto;
  }
  .event-button {
    margin: var(--space-line) 0;
  }
  .featured-block > .feature-container {
    display: flex;
    flex-direction: column;
  }
  .featured-block > .feature-container > .left {
    width: 100%;
  }
  .featured-block > .feature-container > .right {
    width: 100%;
    padding: var(--space-small);
  }
  .featured-left-image {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0px;
  }
}
@media (min-width: 480px) {
  .tag-comp {
    padding: var(--space-base) var(--space-line);
    font-size: var(--font-size-baase);
  }
}
@media (min-width: 718px) {
  .subnav-container {
    padding: var(--space-large) 0 var(--space-medium) 0;
  }
  .subnav-wrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--color-gray);
  }
  .subnav-wrapper .subnav-comp {
    font-size: var(--font-size-h4-large);
    color: var(--color-primary);
    width: fit-content;
    padding: var(--space-line);
    margin-right: var(--space-small);
    cursor: pointer;
    text-align: left;
  }
  .subnav-wrapper .subnav-comp.active {
    color: var(--color-primary);
    font-weight: var(--font-weight-bolder);
    border-bottom: 1px solid var(--color-primary);
    background-color: #fff;
    border-radius: 0;
  }
  .two-column-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--margin-top {
      margin-top: var(--space-xl);
    }
    &--margin-bottom {
      margin-bottom: var(--space-xl);
    }
  }
  .two-column-wrapper .right-container {
    width: 100%;
    margin-bottom: -60px;
  }
  .two-column-wrapper .left-container {
    width: 100%;
  }
  .case-container {
    display: flex;
    flex-direction: column;
  }
  .case-wrapper {
    padding: var(--space-line);
    width: 100%;
  }
  .marketing-container {
    display: flex;
    flex-direction: column;
  }
  .marketing-wrapper {
    padding: var(--space-line);
    width: 100%;
  }
  .two-wide-wrapper {
    padding: var(--space-line);
    width: 100%;
  }
  .one-wide-wrapper {
    padding: var(--space-line);
    width: 100%;
  }
  .kiosk-features-wrapper {
    padding: var(--space-line);
    width: 100%;
  }
  .features-block {
    width: calc(50% - var(--space-line));
  }
  .feature-inner {
    min-height: 210px;
  }
  .pricing-container {
    display: flex;
    flex-direction: column;
  }
  .pricing-wrapper .pricing-block .h5,
  .pricing-wrapper .pricing-block .h3 {
    margin-bottom: var(--space-line);
  }
  .pricing-block {
    margin-bottom: var(--space-small);
  }
  .retail-excel-container {
    display: flex;
    flex-direction: column;
    margin: var(--space-line) 0 var(--space-medium) 0;
    padding-bottom: var(--space-large);
  }
  .store-picture-container {
    flex-direction: row;
  }
  .store-picture-wrapper {
    margin: 0 var(--space-line) var(--space-line) 0;
    flex-basis: calc(50% - var(--space-line));
    width: calc(50% - var(--space-line));
  }
  .event-bar .contents {
    flex-direction: row;
  }
  .event-button {
    margin-left: var(--space-small);
  }
  .featured-block > .feature-container {
    display: flex;
    flex-direction: row;
  }
  .featured-block > .feature-container > .left {
    width: 35%;
  }
  .featured-block > .feature-container > .right {
    width: 65%;
    padding: var(--space-medium);
  }
  .featured-left-image {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
@media (min-width: 1024px) {
  .hero-image-container {
    width: 50%;
  }
  .two-column-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: var(--space-large) 0;
    &--right {
      flex-direction: row-reverse;
    }
    &--margin-top {
      margin-top: var(--space-xxl);
    }
    &--margin-bottom {
      margin-bottom: var(--space-xxl);
    }
  }
  .two-column-wrapper .right-container {
    width: 100%;
    margin-bottom: -80px;
  }
  .two-column-wrapper .product-feature {
    &--right-padding {
      padding: 0 var(--space-medium) 0 0;
    }
    &--left-padding {
      padding: 0 0 0 var(--space-medium);
    }
  }
  .case-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .case-wrapper {
    padding: var(--space-line);
    width: 50%;
  }
  .tag-comp {
    padding: var(--space-base) var(--space-line);
    font-size: var(--font-size-base);
  }
  .marketing-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .marketing-wrapper {
    padding: var(--space-line);
    width: 33%;
  }
  .two-wide-wrapper {
    padding: var(--space-line);
    width: 66%;
  }
  .one-wide-wrapper {
    padding: var(--space-line);
    width: 34%;
  }
  .kiosk-features-wrapper {
    padding: var(--space-line);
    width: 33%;
  }
  .features-block {
    width: calc(25% - var(--space-line));
    aspect-ratio: 1/1;
  }
  .feature-inner {
    min-height: 230px;
  }
  ._content .one-feature-hero {
    padding-bottom: var(--space-xl);
  }
  .pricing-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: var(--space-xl);
  }
  .pricing-wrapper {
    width: 33%;
    padding: var(--space-line);
  }
  .pricing-block {
    margin-bottom: var(--space-medium);
    min-height: 305px;
  }
  .retail-excel-container {
    display: flex;
    flex-direction: row;
    margin: var(--space-line) 0 var(--space-large) 0;
    padding-bottom: var(--space-xl);
  }
  .retail-excel-container .upper {
    width: 70%;
    margin-bottom: 0;
    padding-right: var(--space-small);
  }
  .retail-excel-container .bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
  }
  .retail-excel-container .bottom .detail-block {
    margin-bottom: var(--space-small);
  }
  .store-picture-container {
    margin-bottom: var(--space-xl);
  }
  .case-food-extra-feature-container {
    display: flex;
    flex-direction: row;
  }
  .case-food-extra-feature-comp {
    width: 50%;
  }
  .case-food-extra-feature-comp:first-child {
    margin-right: var(--space-small);
    width: 50%;
  }
  .case-custom-background {
    width: 50%;
  }
  .case-custom-background:first-child {
    margin-right: var(--space-small);
    width: 50%;
  }
}
@media (min-width: 1292px) {
  .two-column-wrapper .right-container {
    width: 50%;
    margin-bottom: -80px;
  }
  .two-column-wrapper .left-container {
    width: 50%;
    // transform: translate(0, -35%);
    flex-direction: column;
    justify-content: center;
  }
  .two-column-wrapper .product-feature {
    &--right-padding {
      padding: 0 var(--space-medium) 0 0;
    }
    &--left-padding {
      padding: 0 0 0 var(--space-medium);
    }
  }
}

// retail

.retail-main-image-bg {
  background: url("../img/retail/retail-main.png") center;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.cafe24-main-image-bg {
  background: url("../img/cafe24/cafe24-main.png") center;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.cafe24-point-image {
  width: 640px;
  height: 465px;
}
.kiosk-main-image-bg {
  background: url("../img/kiosk/kiosk-main.png") center;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: var(--space-small);
}
.waiting-main-image-bg {
  background: url("../img/waiting/waiting-main-large.png") center;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: var(--space-small);
}

@media (max-width: 1292px) {
  .retail-main-image-bg {
    background: url("../img/retail/retail-main.png") center;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .cafe24-main-image-bg {
    background: url("../img/cafe24/cafe24-main-md.png") center;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .cafe24-point-image {
    width: 508px;
    height: 382px;
  }
  .waiting-main-image-bg {
    background: url("../img/waiting/waiting-main-medium.png") center;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: var(--space-small);
  }
}
@media (max-width: 1024px) {
  .cafe24-point-image {
    width: 680px;
    height: 494.5px;
  }
  .narrow-feature {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 500px;
  }
  .narrow-feature > .left {
    margin-top: var(--space-base);
    font-size: var(--font-size-h3-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--font-size-h3-medium);
  }
  .narrow-feature > .right {
    position: absolute;
    top: var(--space-xxl);
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: 350px;
    &--waiting {
      background: url("../img/cafe24/cafe24-waiting.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    &--ali {
      background: url("../img/cafe24/cafe24-ali.png") top;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &--tax-refund {
      background: url("../img/cafe24/cafe24-taxrefund.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
@media (max-width: 718px) {
  .bottom > .image {
    width: 90%;
  }
  .upper > .right > .image,
  .upper > .left > .image {
    width: 90%;
  }
  .retail-stats-container > .bottom {
    min-height: 450px;
  }
  .retail-main-image-bg {
    background: url("../img/retail/retail-main-sm.png") center;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .cafe24-main-image-bg {
    background: url("../img/cafe24/cafe24-main-sm.png") center;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .kiosk-main-image-bg {
    background: url("../img/kiosk/kiosk-main-sm.png") center;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .cafe24-point-image {
    width: 424px;
    height: 308px;
  }
  .waiting-main-image-bg {
    background: url("../img/waiting/waiting-main-small.png") center;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: var(--space-small);
  }
}
@media (max-width: 480px) {
  .cafe24-point-image {
    width: 324px;
    height: 235px;
  }
  .waiting-main-image-bg {
    background: url("../img/waiting/waiting-main-small.png") center;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: var(--space-small);
  }
}

// template
@media (min-width: 150px) {
}
@media (min-width: 480px) {
}
@media (min-width: 718px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1292px) {
}
